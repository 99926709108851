//
// componentsMap 组件Map
// key值必须保持唯一
// 同一目录下的不同组件，尽量在次级目录使用不重复的路径名称，以防遍历处理时出现奇怪的情况（例如不同运行时环境的找不到文件） 20240612
//

export const componentsMap = {
  // example_table: () => import('@/views/table/index'),
  // example_tree: () => import('@/views/tree/index'),
  // form_index: () => import('@/views/form/index'),
  // layout
  layout: () => import('@/layouts/index.vue'),
  // 营销活动
  crowdPackage: () => import('@/views/marketingCtivities/crowdPackage/index.vue'),
  // 人群包
  createCrowdPackage: () => import('@/views/marketingCtivities/crowdPackage/components/createCrowdPackage.vue'),
  // 人群包详情
  viewCrowdPackage: () => import('@/views/marketingCtivities/crowdPackage/components/viewCrowdPackage.vue'),
  // 门店销售报表
  storeSalesReportIndex: () => import('@/views/storeDataReports/storeSalesReportIndex.vue'),
  // 经营分析报表
  businessAnalysisReportIndex: () => import('@/views/storeDataReports/businessAnalysisReportIndex.vue'),
  // 账号通
  accountPassManage: () => import('@/views/accountPass/accountPassIndex.vue'),
  // 空导航（only logo）布局容器组件：强制修改密码
  loginedLayout: () => import('@/layouts/loginedLayout.vue'),
  // 强制修改密码
  forceChangePwdComp: () => import('@/views/changePwd/forceChangePwdComp.vue'),
  // 重置密码
  resetPwdComp: () => import('@/views/resetPwd/resetPwdComp.vue'),
  // permission 权限测试页
  // 菜单管理
  menuConfig: () => import('@/views/permission/menuConfig/menuConfig.vue'),
  // 页面权限
  // pagePermission: () => import('@/views/permission/page.vue'),
  // 指令权限
  // directivePermission: () => import('@/views/permission/directive.vue'),
  // 角色权限
  // rolePermission: () => import('@/views/permission/role.vue'),
  // 角色管理
  // roleConfig: () => import('@/views/permission/roleConfig.vue'),
  // 用户管理
  userManage: () => import('@/views/permission/userManage/userManage.vue'),
  // 权限管理
  permissionManage: () => import('@/views/permission/permissionManage/permissionManage.vue'),
  // 角色管理
  roleManage: () => import('@/views/permission/roleManage/roleManage.vue'),
  // 产品管理
  productManage: () => import('@/views/permission/productManage/productManage.vue'),
  // 短信管理
  smsManage: () => import('@/views/notification/smsManageIndex.vue'),
  // 短信模板管理
  smsTmpl: () => import('@/views/notification/components/smsTmpl.vue'),
  // 创建短信模板
  createSmsTmpl: () => import('@/views/notification/components/CreateSmsTmpl.vue'),
  // 创建短信任务
  createSmsTask: () => import('@/views/notification/components/createSmsTask.vue'),
  // 短信供应商管理
  smsSupplierManage: () => import('@/views/notification/components/smsSupplierManage.vue'),
  // 商户分润管理
  merchantProfitSharing: () => import('@/views/merchantManagement/merchantProfitSharing/merchantProfitSharing.vue'),
  // 分润详情
  merchantProfitSharingDetails: () => import('@/views/merchantManagement/merchantProfitSharing/components/merchantProfitSharingDetailsComp.vue'),
  // 商户入驻管理
  merchantSettled: () => import('@/views/merchantManagement/merchantSettled/merchantSettled.vue'),
  // 新建商户
  createMerchantComp: () => import('@/views/merchantManagement/merchantSettled/components/createMerchantComp.vue'),
  // 商户账号管理
  merchantAccountManageComp: () => import('@/views/merchantManagement/merchantSettled/components/merchantAccountManageComp.vue'),
  // 入驻资料提交
  submitSettledDataComp: () => import('@/views/merchantManagement/merchantSettled/components/submitSettledDataComp.vue'),
  // 商户详情
  verifySettledDetailsComp: () => import('@/views/merchantManagement/merchantSettled/components/verifySettledDetailsComp.vue'),
  // 订单管理列表
  orderManageIndex: () => import('@/views/merchantManagement/orderManage/orderManageIndex.vue'),
  // 订单管理详情
  orderManageDetailsComp: () => import('@/views/merchantManagement/orderManage/components/orderManageDetailsComp.vue'),
  // 商户提现列表
  withDrawIndex: () => import('@/views/merchantClient/withDraw/withDrawIndex.vue'),
  // 商户提现详情
  withDrawDetailsComp: () => import('@/views/merchantClient/withDraw/components/withDrawDetailsComp.vue'),
  // 商户账单管理
  merchantBillManage: () => import('@/views/merchantManagement/merchantBillManage/merchantBillManage.vue'),
  // 商户异常账单管理
  merchantAbnormalBillManage: () => import('@/views/merchantManagement/merchantBillManage/merchantAbnormalBillManage.vue'),
  // 发票管理
  invoiceManageIndex: () => import('@/views/invoiceManage/index.vue'),
  // 发票详情
  invoiceManageDetailsComp: () => import('@/views/invoiceManage/components/invoiceManageDetailsComp.vue'),
  // 个人资料
  profileManageIndex: () => import('@/views/profileManage/profileManageIndex.vue'),
  // 个人信息
  // personalInfo: () => import('@/views/profileManage/components/personalInfoComp.vue'),
  // 智慧来酷
  intelligentLecooIndex: () => import('@/views/intelligentLecoo/intelligentLecooIndex.vue'),
  // 二级嵌套容器
  secondNestedComp: () => import('@/layouts/secondNestedComp.vue'),
  // 顶部tab切换
  organizationManageIndex: () => import('@/views/groupManage/organizationManageIndex.vue'),
  // 组织架构集团
  groupManageComp: () => import('@/views/groupManage/components/groupManageComp.vue'),
  // 组织架构集团数据添加
  createGroupComp: () => import('@/views/groupManage/components/createGroupComp.vue'),
  // 组织架构公司
  companyManageComp: () => import('@/views/groupManage/components/companyManageComp.vue'),
  // 组织架构公司数据添加
  createCompanyComp: () => import('@/views/groupManage/components/createCompanyComp.vue'),
  // 组织架构部门
  departmentManageComp: () => import('@/views/groupManage/components/departmentManageComp.vue'),
  // 组织架构部门数据添加
  createDepartmentComp: () => import('@/views/groupManage/components/createDepartmentComp.vue'),
  // 黑名单管理
  blackListManage: () => import('@/views/blackListManage/blackListManageIndex.vue'),
  // 黑名单新建
  createBlackListComp: () => import('@/views/blackListManage/components/createBlackListComp.vue'),
  // 财务模块-->
  // 基础数据-->财务基础数据
  basicDataFinace: () => import('@/views/financialModule/basicDataManage/basicDataFinace/index.vue'),
  // 基础数据-->财务基础数据-->财务账套信息管理
  finacialAccountInfoManage: () => import('@/views/financialModule/basicDataManage/basicDataFinace/components/finacialAccountInfoManage.vue'),
  // 手工调整表
  // 手工调整表-任务列表
  manualAdjustTaskList: () => import('@/views/financialModule/manualAdjustTable/manualAdjustTaskList/index.vue'),
  // 手工调整表-导入文件列表
  manualAdjustImportList: () => import('@/views/financialModule/manualAdjustTable/manualAdjustImportList/index.vue'),
  // 手工调整表-查询列表
  manualAdjustQueryList: () => import('@/views/financialModule/manualAdjustTable/manualAdjustQueryList/index.vue'),
  // 应收核算
  // 应收核算-任务列表
  accountsReceivableTaskList: () => import('@/views/financialModule/accountsReceivable/accountsReceivableTaskList/index.vue'),
  // 应收核算-导入文件列表
  accountsReceivableImportList: () => import('@/views/financialModule/accountsReceivable/accountsReceivableImportList/index.vue'),
  // 应收核算-查询列表
  accountsReceivableQueryList: () => import('@/views/financialModule/accountsReceivable/accountsReceivableQueryList/index.vue'),
  // 凭证列表
  viewVoucherIndex: () => import('@/views/financialModule/viewVoucher/index.vue'),
  // 凭证详情
  voucherDetails: () => import('@/views/financialModule/viewVoucher/components/voucherDetails.vue'),
  // 银行流水
  // bankVoucherIndex: () => import('@/views/financialModule/bankVoucher/index.vue'),
  // 银行流水-任务创建
  bVTaskCreateIndex: () => import('@/views/financialModule/bankVoucher/taskCreate/index.vue'),
  // 银行流水-上传文件
  bVFileUploadIndex: () => import('@/views/financialModule/bankVoucher/fileUpload/index.vue'),
  // 银行流水-余额查询
  bVGetBalanceIndex: () => import('@/views/financialModule/bankVoucher/getBalance/index.vue'),
  // 银行流水-查询银行流水
  bVGetBankFlowIndex: () => import('@/views/financialModule/bankVoucher/getBankFlow/index.vue'),
  // 银行流水-上传文件列表
  bVFileUploadListIndex: () => import('@/views/financialModule/bankVoucher/fileUploadList/index.vue'),
  // 基础数据scm-财务结账信息对照
  bDSCheckoutNC: () => import('@/views/financialModule/basicDataSCM/checkoutNC/index.vue'),
  // 基础数据NC：业务单元
  bdncBusinessUnit: () => import('@/views/financialModule/basicDataNC/businessUnit/index.vue'),
  // 基础数据NC：税率
  bdncTaxRate: () => import('@/views/financialModule/basicDataNC/taxRate/index.vue'),
  // 基础数据NC：会计科目-集团
  bdncAccountingSubjectGroup: () => import('@/views/financialModule/basicDataNC/accountingSubjectGroup/index.vue'),
  // 基础数据NC：OA供应商&nc对照
  bdncSupplierMapping: () => import('@/views/financialModule/basicDataNC/supplierMapping/index.vue'),
  // 基础数据NC：OA供应商&客户对照
  bdncCustomerMapping: () => import('@/views/financialModule/basicDataNC/customerMapping/index.vue'),
  // 银行流水-任务结果统计
  taskConclusionStastics: () => import('@/views/financialModule/bankVoucher/taskConclusionStastics/index.vue'),
  // 同步日志-nc同步日志
  slNcSyncLog: () => import('@/views/financialModule/syncLog/ncSyncLog/index.vue'),
  // 手工表-上传
  mdsFileUpload: () => import('@/views/financialModule/manualDataSheet/mdsFileUpload/index.vue'),
  // 手工表-文件列表
  mdsFileUploadList: () => import('@/views/financialModule/manualDataSheet/mdsFileUploadList/index.vue'),
  // 业务招待费
  oaBizExpense: () => import('@/views/financialModule/basicDataOA/oaBizExpense/index.vue'),
  // 预算项目档案
  oaBudgetProject: () => import('@/views/financialModule/basicDataOA/oaBudgetProject/index.vue'),
  // 成本中心档案
  oaCostCenter: () => import('@/views/financialModule/basicDataOA/oaCostCenter/index.vue'),
  // 付款公司档案
  oaPayCompany: () => import('@/views/financialModule/basicDataOA/oaPayCompany/index.vue'),
  // 供应商管理
  oaSupplierManage: () => import('@/views/financialModule/basicDataOA/oaSupplierManage/index.vue'),
  // 券规则管理
  promotionVoucherRule: () => import('@/views/promotionVoucher/promotionVoucherRule/index.vue'),
  // 新增券规则
  createPromotionVoucherRule: () => import('@/views/promotionVoucher/promotionVoucherRule/components/createPromotionVoucherRule.vue'),
  // 券管理
  promotionVoucherManage: () => import('@/views/promotionVoucher/promotionVoucherManage/index.vue'),
  // 创建券
  createPromotionVoucher: () => import('@/views/promotionVoucher/promotionVoucherManage/components/createPromotionVoucher.vue'),
  // 优惠券券发放
  releasePromotionVoucher: () => import('@/views/promotionVoucher/releasePromotionVoucher/index.vue'),
  // 新增发放
  createReleasePromotionVoucher: () => import('@/views/promotionVoucher/releasePromotionVoucher/components/createReleasePromotionVoucher.vue'),
  // 发放明细列表
  releasePromotionVoucherDetails: () => import('@/views/promotionVoucher/releasePromotionVoucher/components/releasePromotionVoucherDetails.vue'),
  // 银行信息管理
  bankinfoMaintain: () => import('@/views/financialModule/bankVoucher/bankinfoMaintain/index.vue'),
  // 查看、编辑银行信息
  editBankinfo: () => import('@/views/financialModule/bankVoucher/bankinfoMaintain/components/editBankinfo.vue'),
  // 后返资源管理
  // 后返资源管理：预算案
  budgetCaseIndex: () => import('@/views/postRebate/budgetCase/budgetCaseIndex.vue'),
  // 后返资源管理：创建预算案（单台、非单台）
  editBudgetCaseComp: () => import('@/views/postRebate/budgetCase/components/editBudgetCaseComp.vue'),
  // 后返资源管理：资源包
  resourcePackIndex: () => import('@/views/postRebate/resourcePack/resourcePackManage/resourcePackIndex.vue'),
  // 后返资源管理：资源包-添加资源包
  editResourcePackComp: () => import('@/views/postRebate/resourcePack/resourcePackManage/components/editResourcePackComp.vue'),
  // 后返资源管理：产品部分类资源包
  productCategoriesRPIndex: () => import('@/views/postRebate/resourcePack/productCategoriesResourcePack/productCategoriesRPIndex.vue'),
  // 后返资源管理：结算案
  settlementCaseIndex: () => import('@/views/postRebate/settlementCase/settlementCaseIndex.vue'),
  // 后返资源管理：创建结算案（单台、非单台）
  editSettlementCaseComp: () => import('@/views/postRebate/settlementCase/components/editSettlementCaseComp.vue'),
  // 后返资源管理：待办
  postRebateUpcomingIndex: () => import('@/views/postRebate/postRebateUpcoming/postRebateUpcomingIndex.vue'),
  // 预算案审批
  auditBudgetCaseComp: () => import('@/views/postRebate/budgetCase/components/editBudgetCaseComp.vue'),
  // 结算案审批
  auditSettlementCaseComp: () => import('@/views/postRebate/settlementCase/components/editSettlementCaseComp.vue'),
}
