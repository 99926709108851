import { VxeTablePropTypes } from "vxe-table"
// 公共store
const useCommonsStore = defineStore(
  // 唯一ID
  'commons',
  () => {
    // form元素公共尺寸 Cookies.get('size') || 
    const size = ref('medium')
    // 当前设备（桌面）尺寸类型
    const device = ref('desktop')
    // 每页显示个数选择器数据 ref(<Number[]>[10, 20, 30, 50, 70, 100, 150, 200, 300, 500, 1000, 2000, 3000, 5000, 10000])
    const pageSizeList = ref(<number[]>[10, 20, 30, 50, 70, 100])
    // vxe-table 全局通用配置
    const rowConfig = ref({
      isHover: true,
      isCurrent: true,
      useKey: true,
      // keyField: _X_ROW_KEY
    })
    const columnConfig = ref<VxeTablePropTypes.ColumnConfig>({
      useKey: true,
      // 表头列宽拖拽调整
      resizable: true
    })
    // vxe-table 最小高度（不能小于此高度，会出现滚动透视到固定层）
    const vxeTableMinHeight = ref(98)
    // 加密秘钥（目前登录在用）
    const cryptoKey = ref('')
    /* 全局记录校验登录有效状态初始时间，并在校验后更新（路由守卫跳转） */
    const globalTokenUpdateTime = ref(0)
    /** @description 会话有效时长，当前半小时 30 * 60 * 1000 */
    const globalTokenDeviderTime = ref(30 * 60 * 1000)

    /** @description 设置加密key */
    async function setCryptoKey(param: string) {
      // console.log('set setCryptoKey at store', param)
      cryptoKey.value = param
    }
    /** @description 设置全局登录登录校验时间 */
    async function setGlobalTokenUpdateTime(param: any) {
      const enabledTUT = localStorage.getItem('enabled_tut')
      if (enabledTUT === '1') {
        console.log('set global token updatetime at store', param)
      }
      globalTokenUpdateTime.value = param
    }

    return {
      size,
      device,
      pageSizeList,
      rowConfig,
      columnConfig,
      vxeTableMinHeight,
      cryptoKey,
      globalTokenUpdateTime,
      globalTokenDeviderTime,
      setCryptoKey,
      setGlobalTokenUpdateTime
    }
  }
)

export default useCommonsStore
